



















import { Component, Mixins, Vue } from 'vue-property-decorator';
import SearchField from '@/ui/components/molecules/inputs/SearchField.vue';
import AlphabetIndex from '@/ui/components/organisms/tags/AlphabetIndex.vue';
import TagOverview from '@/ui/components/molecules/tags/TagOverview.vue';
import TagArchiveTitle from '@/ui/components/atoms/tags/TagArchiveTitle.vue';
import { Languages } from '@/core/admin/constants/Languages.const';
import sortTagsByStartingLetter from '@/core/tags/services/SortTagsByStartingLetter';
import { SuccessAlertMixin } from '@/ui/mixins/SuccessAlertMixin';
import type { Tag } from '@/core/tags/models/Tag';
import { findTagsUseCase } from '@/core/tags/usecases/findTagsUseCase';
import { TagType } from '@/core/tags/enums/TagType';
import { Loader } from '@/ui/util/decorators/loader';
import { whitespaceTrim } from '@/ui/services/tags/tagService';

interface VueComponent extends Vue {
  $refs: Record<string, Array<Vue>>;
}

@Component({
  name: 'TagArchiveView',
  components: { TagArchiveTitle, TagOverview, AlphabetIndex, SearchField },
})
export default class TagArchiveView extends Mixins(SuccessAlertMixin) {
  private allTags: Array<Tag> = [];
  private backupAllTags: Array<Tag> = [];
  private letterKeys: Array<string> = [];
  private searchQuery = '';

  mounted() {
    this.fetchTags();
  }

  @Loader
  async fetchTags() {
    const tags = await findTagsUseCase(
      { type: TagType.GENERAL, search: '', size: 1500 },
      this.$store.state.userStore.currentUser,
    );
    this.allTags = tags;
    this.backupAllTags = [...tags];
    this.searchQueryChange(this.searchQuery);
  }

  @Loader
  searchQueryChange(query: string) {
    this.searchQuery = query;
    if (query === '') {
      this.allTags = this.backupAllTags;
    } else if (query?.length >= 3) {
      const newTags = this.backupAllTags.filter((tag) => {
        return tag.value[Languages.DE].toLowerCase().includes(query.toLowerCase());
      });
      this.allTags = newTags;
    }
  }

  get tagListRefs(): Record<string, Array<Vue>> {
    const tagOverviewComponent = this.$refs.tagOverview as VueComponent;
    return tagOverviewComponent.$refs;
  }

  get letterIndexes(): Array<string> {
    const keys = [...Object.keys(this.tagsKeys)];
    keys.sort();
    const upperCaseKeys: Array<string> = [];
    keys.forEach((key) => upperCaseKeys.push(key.toUpperCase()));
    return upperCaseKeys;
  }

  get tags(): Array<Tag> {
    return this.allTags.map((tag) => {
      tag.value = whitespaceTrim(tag.value);
      return tag;
    });
  }
  get tagsKeys(): Record<string, Array<Tag>> {
    return sortTagsByStartingLetter(this.allTags);
  }
}
