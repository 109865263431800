var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headers,"items":_vm.tableValues,"sort-by":"text","single-expand":false,"group-by":"category","disable-pagination":"","hide-default-footer":true,"hide-default-header":true,"show-group-by":""},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var group = ref.group;
var isOpen = ref.isOpen;
var toggle = ref.toggle;
return [_c('div',{staticClass:"group d-flex justify-space-between pr-4 pl-2"},[_c('TagTitle',{ref:group,attrs:{"letter":group}}),_c('v-icon',{on:{"click":toggle}},[_vm._v(_vm._s(isOpen ? 'mdi-minus' : 'mdi-plus')+" ")]),_vm._v(" "+_vm._s(group[0].vendor)+" ")],1),(isOpen)?_c('v-subheader',{staticClass:"item-container subheader"},_vm._l((_vm.languageSubheaders),function(language,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(_vm.$t(language))+" ")])}),0):_vm._e()]}},{key:"item.text",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"item-container"},[_vm._l((item.tag.value),function(translation,index){return _c('div',{key:index,staticClass:"item-text",class:_vm.isFirstLanguage(index)},[_vm._v(" "+_vm._s(translation)+" ")])}),[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.onEditTag(item.tag)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ma-0",attrs:{"right":"","color":"accent"}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('shared.common.tooltip-edit')))])])],1)]],2)]}}],null,true)}),_c('TagDialog',{staticClass:"edit-dialog",attrs:{"show-dialog":_vm.showEditTagDialog,"tag":_vm.selectedTag,"is-edit-tag":true},on:{"update":_vm.emitUpdate,"removeClick":_vm.deleteTagStarted,"close":_vm.onDialogClosed}}),_c('DeleteTagDialog',{attrs:{"open-dialog":_vm.openDeleteDialog,"tag":_vm.tagToDelete},on:{"closeDialog":_vm.closeDeleteDialog,"update":function($event){return _vm.emitUpdate()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }