


















import { Component, Prop, Vue } from 'vue-property-decorator';
import Alphabet from '@/core/const/Alphabet.const';

@Component({
  name: 'AlphabetIndex',
})
export default class AlphabetIndex extends Vue {
  @Prop({ type: Array, required: true }) letters!: Array<string>;
  @Prop({ type: Object, required: true }) refs!: Record<string, Array<Vue>>;

  get alphabet() {
    return ['#'].concat(Alphabet);
  }
  getLetterColor(letter: string) {
    if (this.letters.includes(letter)) {
      return '#8c99ad';
    } else {
      return '#edf0f6';
    }
  }

  isLetterActive(letter: string): boolean {
    return this.letters.includes(letter);
  }

  goToLetter(letter: string) {
    if (this.isLetterActive(letter)) {
      const elementToGoTo = this.refs[letter.toLowerCase()];
      const tagOverviewWrapper = '.tagOverviewWrapper';
      this.$vuetify.goTo(elementToGoTo as unknown as HTMLElement, {
        container: tagOverviewWrapper,
        offset: -125,
        easing: 'easeInOutCubic',
        duration: 1000,
      });
    }
  }
}
