















import { Component, Vue } from 'vue-property-decorator';
import TagDialog from '@/ui/components/organisms/dialogs/TagDialog.vue';
import ButtonWithTextAndIcon from '@/ui/components/atoms/buttons/ButtonWithTextAndIcon.vue';

@Component({
  name: 'TagArchiveTitle',
  components: { TagDialog, ButtonWithTextAndIcon },
})
export default class LabelArchiveTitle extends Vue {
  protected showDialog = false;

  setDialogState() {
    this.showDialog = !this.showDialog;
  }
}
