





















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { CreateTagDTO } from '@/core/tags/DTO/CreateTagDTO';
import { TagType } from '@/core/tags/enums/TagType';
import type { Tag } from '@/core/tags/models/Tag';
import { getEmptyLanguageObject, whitespaceTrim } from '@/ui/services/tags/tagService';
import { cloneDeep as _cloneDeep } from 'lodash';
import { UpdateTagDTO } from '@/core/tags/DTO/UpdateTagDTO';
import i18n from '@/i18n';

interface VForm extends HTMLFormElement {
  validate(): boolean;
  reset(): boolean;
}

@Component({
  name: 'TagForm',
})
export default class NewTagForm extends Vue {
  @Prop({ default: false }) saveNew: boolean | undefined;
  @Prop({ default: false }) saveUpdate: boolean | undefined;
  @Prop({ default: null }) tag: Tag | undefined;
  private selectedTag = {
    valid: false,
    fields: getEmptyLanguageObject(),
    rules: {
      'de-CH': [this.requiredRule('de-CH')],
      'fr-CH': [this.requiredRule('fr-CH')],
      'it-CH': [this.requiredRule('it-CH')],
      'en-CH': [this.requiredRule('en-CH')],
    },
  };

  mounted() {
    if (this.tag) {
      this.selectedTag.fields = _cloneDeep(this.tag.value);
    }
  }

  @Watch('tag')
  onChange() {
    if (this.tag) {
      this.selectedTag.fields = _cloneDeep(this.tag.value);
    }
  }

  @Watch('saveNew')
  onSave(newValue: boolean) {
    if (this.isAllInputValid() && newValue) {
      const tagToCreate: CreateTagDTO = {
        type: TagType.GENERAL,
        value: whitespaceTrim(this.selectedTag.fields),
      };
      this.$emit('createTagValidInput', tagToCreate);
    } else {
      this.$emit('inputIsInvalid');
    }
  }

  @Watch('saveUpdate')
  onUpdate(newValue: boolean) {
    if (this.tag) {
      if (this.isAllInputValid() && newValue) {
        const tagToUpdate: UpdateTagDTO = {
          value: whitespaceTrim(this.selectedTag.fields),
        };
        this.$emit('updateTagValidInput', tagToUpdate);
      } else {
        this.$emit('inputIsInvalid');
      }
    }
  }

  requiredRule(language: string): (value: unknown) => boolean | string {
    if (language === i18n.i18next.language) {
      return (value) => !!value || value === 0 || this.$t('shared.common.error-required-field');
    }
    return () => true;
  }

  isAllInputValid() {
    const ref = this.$refs.form as VForm;
    return ref.validate();
  }

  clearInput() {
    this.selectedTag.fields = getEmptyLanguageObject();
  }
}
