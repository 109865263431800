



















import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'SearchField',
})
export default class SearchField extends Vue {
  search = '';
  emitChangeQuery() {
    this.$emit('queryChange', this.search);
  }
}
