




































































import { Component, Prop, Vue } from 'vue-property-decorator';
import type { Tag } from '@/core/tags/models/Tag';
import { HeaderInfo } from '@/core/admin/models/UserDataTable';
import TagTitle from '@/ui/components/atoms/tags/TagTitle.vue';
import type { LanguageObject } from '@/core/campaign/models/LanguageObject';
import i18n from '@/i18n';
import DeleteTagDialog from '@/ui/components/organisms/dialogs/DeleteTagDialog.vue';
import { TagType } from '@/core/tags/enums/TagType';
import { getEmptyCampaignTag } from '@/ui/services/tags/tagService';
import Alphabet from '@/core/const/Alphabet.const';
import TagDialog from '@/ui/components/organisms/dialogs/TagDialog.vue';

@Component({
  name: 'TagOverview',
  components: { TagDialog, TagTitle, DeleteTagDialog },
})
export default class TagOverview extends Vue {
  @Prop({ required: true, type: Array }) dictionary!: Array<Tag>;
  protected openDeleteDialog = false;
  protected tagToDelete: Tag | undefined = getEmptyCampaignTag(TagType.GENERAL) as Tag;
  private readonly locale: keyof LanguageObject = i18n.i18next.language;
  private selectedTag: Tag = getEmptyCampaignTag(TagType.GENERAL) as Tag;
  private showEditTagDialog = false;

  get languageSubheaders() {
    return [
      'tag-values.languages.language-DE',
      'tag-values.languages.language-FR',
      'tag-values.languages.language-IT',
      'tag-values.languages.language-EN',
    ];
  }

  get tableValues() {
    const table: Array<unknown> = [];
    this.dictionary.forEach((tag) => {
      const locale = i18n.i18next.language as keyof LanguageObject;
      const name = tag.value[locale];
      if (name[0]) {
        let startingLetter = name[0].toLowerCase();
        if (!Alphabet.includes(startingLetter.toUpperCase())) {
          startingLetter = '#';
        }
        table.push({
          text: name,
          category: startingLetter,
          tag: tag,
        });
      }
    });
    return table;
  }

  get headers(): Array<HeaderInfo> {
    return [{ text: 'letter', value: 'text', sortable: false }];
  }

  onDialogClosed() {
    this.selectedTag = getEmptyCampaignTag(TagType.GENERAL) as Tag;
    this.showEditTagDialog = false;
  }

  onEditTag(tag: Tag) {
    this.selectedTag = tag;
    this.showEditTagDialog = true;
  }

  isFirstLanguage(index: unknown) {
    if (this.locale == index) {
      return 'bold-translation';
    }
  }

  getTranslations(item: Tag) {
    return Object.values(item.value)
      .filter((value) => value != '')
      .join(', ');
  }

  emitUpdate() {
    this.$emit('update');
  }

  closeDeleteDialog() {
    this.openDeleteDialog = false;
  }

  deleteTagStarted(tag: Tag) {
    this.tagToDelete = tag;
    this.openDeleteDialog = true;
  }
}
