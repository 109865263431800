















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import type { Tag } from '@/core/tags/models/Tag';
import { deleteTagUseCase } from '@/core/tags/usecases/deleteTagUseCase';
import { AlertStoreActions, AlertType } from '@/ui/stores/AlertStore';

@Component({
  name: 'DeleteTagDialog',
})
export default class DeleteUserDialog extends Vue {
  @Prop() tag!: Tag;
  @Prop({ type: Boolean, required: true }) openDialog!: boolean;
  dialog = false;
  isLoading = false;

  async deleteTag() {
    if (this.tag.id) {
      this.isLoading = true;
      deleteTagUseCase(this.tag.id, this.$store.state.userStore.currentUser)
        .then(() => {
          this.$store.dispatch(AlertStoreActions.TRIGGER_ALERT, {
            message: 'LABELS.Overview.successAlertDeleted',
            type: AlertType.SUCCESS,
          });
          this.$emit('update');
          this.closeDialog();
        })
        .catch(() => {
          this.$store.dispatch(AlertStoreActions.TRIGGER_ALERT, {
            message: 'LABELS.Overview.errorAlertDelete',
            type: AlertType.ERROR,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }

  @Watch('openDialog')
  onOpenDialogChange(newVal: boolean) {
    this.dialog = newVal;
  }

  closeDialog(): void {
    this.$emit('closeDialog');
  }
}
