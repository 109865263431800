import type { Tag } from '@/core/tags/models/Tag';
import i18n from '@/i18n';
import type { LanguageObject } from '@/core/campaign/models/LanguageObject';
import Alphabet from '@/core/const/Alphabet.const';

export default function sortTagsByStartingLetter(tags: Array<Tag>): Record<string, Array<Tag>> {
  const dictionary: Record<string, Array<Tag>> = {};
  tags.forEach((tag) => {
    const locale = i18n.i18next.language as keyof LanguageObject;
    const name = tag.value[locale];
    if (name[0]) {
      let startingLetter = name[0].toLowerCase();
      if (!Alphabet.includes(startingLetter.toUpperCase())) {
        startingLetter = '#';
      }
      if (dictionary[startingLetter]) {
        dictionary[startingLetter].push(tag);
      } else {
        dictionary[startingLetter] = [tag];
      }
    }
  });
  return dictionary;
}
