import { Component, Vue } from 'vue-property-decorator';

@Component
export class SuccessAlertMixin extends Vue {
  alertMessage = '';
  showSuccessAlert = false;

  alertClosed() {
    this.alertMessage = '';
    this.showSuccessAlert = false;
  }

  setSuccessAlert(payload: SuccessMessagePayload) {
    this.alertMessage = payload.message;
    this.showSuccessAlert = true;
  }
}

export interface SuccessMessagePayload {
  message: string;
}
