





































































import { Vue, Component, Prop } from 'vue-property-decorator';
import type { Tag } from '@/core/tags/models/Tag';
import DialogTitle from '@/ui/components/atoms/textItems/DialogTitle.vue';
import TagForm from '@/ui/components/organisms/forms/TagForm.vue';
import createTagUseCase from '@/core/tags/usecases/createTagUseCase';
import { AlertStoreActions, AlertType } from '@/ui/stores/AlertStore';
import { CreateTagDTO } from '@/core/tags/DTO/CreateTagDTO';
import { UpdateTagDTO } from '@/core/tags/DTO/UpdateTagDTO';
import { updateTagUseCase } from '@/core/tags/usecases/updateTagUseCase';
import type { User } from '@/core/admin/models/User';
import ButtonWithText from '@/ui/components/atoms/buttons/ButtonWithText.vue';

@Component({
  name: 'TagDialog',
  components: { TagForm, ButtonWithText, DialogTitle },
})
export default class TagDialog extends Vue {
  @Prop({ default: null }) tag: Tag | undefined;
  @Prop({ type: Boolean }) showDialog!: boolean;
  @Prop({ type: Boolean }) isNewTag!: boolean;
  @Prop({ type: Boolean }) isEditTag!: boolean;
  triggerSaveNewOnForm = false;
  triggerSaveUpdateOnForm = false;
  isLoading = false;

  get currentUser(): User {
    return this.$store.state.userStore.currentUser;
  }

  async createTagValidInput(tagToCreate: CreateTagDTO) {
    this.isLoading = true;
    createTagUseCase(tagToCreate, this.currentUser)
      .then(() => {
        this.closeDialog();
        this.$store.dispatch(AlertStoreActions.TRIGGER_ALERT, {
          message: 'LABELS.Overview.successAlert',
          type: AlertType.SUCCESS,
        });
        this.$emit('created');
      })
      .catch(() => {
        this.$store.dispatch(AlertStoreActions.TRIGGER_ALERT, {
          message: 'LABELS.Overview.errorAlertCreate',
          type: AlertType.ERROR,
        });
      })
      .finally(() => {
        this.triggerSaveNewOnForm = false;
        this.isLoading = false;
      });
  }

  async updateTagValidInput(tagToUpdate: UpdateTagDTO) {
    if (this.tag) {
      this.isLoading = true;
      updateTagUseCase(this.tag.id, tagToUpdate, this.currentUser)
        .then(() => {
          this.closeDialog();
          this.$store.dispatch(AlertStoreActions.TRIGGER_ALERT, {
            message: 'LABELS.Overview.successAlertUpdated',
            type: AlertType.SUCCESS,
          });
          this.$emit('update');
        })
        .catch(() => {
          this.$store.dispatch(AlertStoreActions.TRIGGER_ALERT, {
            message: 'LABELS.Overview.errorAlertUpdated',
            type: AlertType.ERROR,
          });
        })
        .finally(() => {
          this.triggerSaveUpdateOnForm = false;
          this.isLoading = false;
        });
    }
  }

  handleInvalidInput() {
    this.triggerSaveNewOnForm = false;
    this.triggerSaveUpdateOnForm = false;
  }

  closeDialog() {
    this.$emit('close');
    this.clearInput();
  }

  clearInput() {
    const ref = this.$refs.tagForm as TagForm;
    ref.clearInput();
  }

  createTag() {
    this.triggerSaveNewOnForm = true;
  }

  updateTag() {
    this.triggerSaveUpdateOnForm = true;
  }

  deleteTag() {
    this.$emit('close');
    this.$emit('removeClick', this.tag);
  }
}
