






import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'LabelTitle',
})
export default class TagTitle extends Vue {
  @Prop({ type: String, required: true }) letter!: string;
}
